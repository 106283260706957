.link {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: white;
  padding: 0 0.6em;
  border-radius: 20px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link.active {
  border-color: #d49633;
  color: #d49633 !important;
}

.link :global .anticon,
.link :global .ant-avatar {
  margin-right: 3px;
  width: 1em;
  height: 1em;
}
