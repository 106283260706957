.item {
  background: #f7f7f7;
  padding: 8px 15px;
  word-wrap: break-word;
  word-break: break-all;
}

.item:hover {
  background: #f0f0f0;
}

.item + .item {
  margin-top: 5px;
}

.item a {
  display: block;
}
