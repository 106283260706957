.categoryMark {
  margin-left: 0.5em;
  font-size: 10px;
  line-height: 1rem;
  text-transform: uppercase;
}

.hot {
  color: #ffae1b;
}

.new {
  color: #ff3737;
}
